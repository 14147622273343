import Pagination from '@/component/base-pagination/index.vue';
import flight_openApi_accountMange_buyerPlatformFlightConfig from '@/lib/data-service/flight/flight_openApi_accountMange_buyerPlatformFlightConfig'
import flight_openApi_accountMange_operationLogList from '@/lib/data-service/flight/flight_openApi_accountMange_operationLogList'
import flight_openApi_accountMange_buyerFlightConfigEdit from '@/lib/data-service/flight/flight_openApi_accountMange_buyerFlightConfigEdit'
export default {
    data() {
        return {
            loading: false,
            activeName: 'first',
            configList: [],
            form: {
                companyName: '',
                companyCode: '',
                yzlSecret: '',
                status: '',
                payUrl: '',
                ticketUrl: '',
                fillingPayUrl: '',
                changeUrl: '',
                refundUrl: '',
                airborneUrl: '',
            },
            more: false,
            logList: [],
            logParams: {
                tableLogType: 1,
                callbackUrlId: '',
                pageSize: 10,
                currentPage: 1,
                total: 0,
                businessId: '',
            },
            logLoading: false,
        }
    },
    components: {Pagination},
    created() {
    },
    mounted() {
    },
    activated() {
        this.getConfigInfo()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getConfigInfo(companyUserId) {
            this.loading = true;
            flight_openApi_accountMange_buyerPlatformFlightConfig({companyUserId}).then(res => {
                this.configList = res.configList;
                this.activeName = this.configList[0].companyCode || '1';
                this.changeTab(this.activeName)
            }).finally(() => {
                this.loading = false
            })
        },
        changeTab(val) {
            let hasCode = false;
            this.configList.forEach(value => {
                if (value.companyCode === val) {
                    hasCode = true;
                    // 赋值给表单
                    this.form = JSON.parse(JSON.stringify(value))
                    // 重置日志列表
                    this.reactLog(this.form.callbackUrlId, this.form.businessId)
                }
            });
            if (!hasCode) {
                this.form = JSON.parse(JSON.stringify(this.configList[0]))
                // 重置日志列表
                this.reactLog(this.form.callbackUrlId, this.form.businessId)
            }
        },
        tabClick(val) {
            this.changeTab(this.activeName)
        },
        reactLog(val, businessId) {
            this.logParams = {
                tableLogType: 1,
                callbackUrlId: val,
                pageSize: 10,
                currentPage: 1,
                total: 0,
                businessId,
            };
            this.more = false;
            this.getLogList()
        },
        getLogList() {
            this.logLoading = true;
            flight_openApi_accountMange_operationLogList(this.logParams).then(res => {
                this.logList = res.pageResult.pageData;
                this.logParams.total = res.pageResult.totalCount;
            }).finally(() => {
                this.logLoading = false;
            })
        },
        toggleShow() {
            this.more = !this.more
        },
        submit() {
            this.loading = true;
            let form = JSON.parse(JSON.stringify(this.form));
            form.yinzhilvName = form.userName;
            form.id = form.callbackUrlId;
            flight_openApi_accountMange_buyerFlightConfigEdit(form).then(res => {
                this.$message({
                    type: "success",
                    message: '保存成功'
                });
                this.reactLog(this.form.callbackUrlId, this.form.businessId)
            }).finally(() => {
                this.loading = false;
            })
        },
        back() {
            this.$router.go(-1)
        },
    }
}