// flight_openApi_accountMange_buyerPlatformFlightConfig
const __request = require(`./__request/__request_contentType_json`);
const flight_openApi_accountMange_buyerPlatformFlightConfig = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix:'/flight/openApi/accountMange/buyerPlatformFlightConfig',
        data:data
    }

    return __request(pParameter)
}
module.exports = flight_openApi_accountMange_buyerPlatformFlightConfig