// flight_openApi_accountMange_operationLogList
const __request = require(`./__request/__request_contentType_json`);
const flight_openApi_accountMange_operationLogList = (pParameter) => {
    if (!pParameter) pParameter = {};

    pParameter = {
        method: 'post',
        urlSuffix:"/flight/openApi/accountMange/operationLogList",
        data: pParameter
    };

    return __request(pParameter)
};
module.exports = flight_openApi_accountMange_operationLogList;