// flight_openApi_accountMange_buyerFlightConfigEdit
const __request = require(`./__request/__request_contentType_json`);

export default function flight_openApi_accountMange_buyerFlightConfigEdit(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/flight/openApi/accountMange/buyerFlightConfigEdit'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}